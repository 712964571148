// import React from 'react';
import { useEffect } from 'react';
import { navigate } from 'gatsby';
// import Mobile from './mobile';
// import Home from './home';
// import useViewport from '../hooks/useViewport';

// const BREAK_POINT = 720;
export default () => {
    useEffect(() => {
        navigate(isH5() ? '/mobile/' : '/home/', { replace: true });
    }, []);
    return null;
    // 2020/06/04 打包之后width(window.innderWidth)有误 故更新为上述
    // const { width } = useViewport();
    // return width < BREAK_POINT ? <Mobile /> : <Home />;
};

const isH5 = ()  => {
    const inMobile = window.location.href.match(/mobile/i) !== null;
    if (inMobile) return false;

    const sUserAgent = navigator.userAgent.toLowerCase();
    const bIsIpad = sUserAgent.match(/ipad/i) !== null;
    const bIsIphoneOs = sUserAgent.match(/iphone os/i) !== null;
    const bIsMidp = sUserAgent.match(/midp/i) !== null;
    const bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) !== null;
    const bIsUc = sUserAgent.match(/ucweb/i) !== null;
    const bIsAndroid = sUserAgent.match(/android/i) !== null;
    const bIsCE = sUserAgent.match(/windows ce/i) !== null;
    const bIsWM = sUserAgent.match(/windows mobile/i) !== null;
    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
        console.log('移动端网站地址');
        return true;
    }
    console.log('PC端网站地址');
    return false;
}
